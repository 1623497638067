import React from "react"
import '../styles/index.scss';

class Page404 extends React.Component {

render() {
  return (<div>
        <main>
            <p><a href="/">Logo img</a></p>
            <h2>404</h2>
        </main>
        <footer>
            <small>2020 Copyright Evitat. All rights reserved</small>
        </footer>
    </div>)
}
}


export default Page404

